<script>
  import Vue from 'vue';
  import BaseVue from '@frontend/Base.vue';
  import Gen from '../helper/Gen';
  
  import VueTabs from 'vue-nav-tabs';
  import 'vue-nav-tabs/themes/vue-tabs.css';
  Vue.use(VueTabs)
  
  export default {
  	extends: BaseVue,
  	data() {
  		return {
  			article: {},
  			title_head: '',
  			tab: '',
        miniHealth: 'Mini Health',
  			topicTerkait: {},
  			relatedProduct: {},
  			subCat: {},
        foodMeter: {},
        foodInput: {},
        resultFood: {},
        foodStatus: false,
        activity: {},
        activityInput: {},
        resultactivity: {},
        activityStatus: false,
        pradiabet:{},
        input:[],
        resultPradiabet:{},
        pradiabetStatus:false,
        inputBmi:{},
        bmiStatus:false,
        resultBmi:{},
        bmi:0,
        countIsactive:0,
        heartDisease:{},
        heartDiseaseResult:{},
        heartStatus:false,
        heartInput:{gender:'L'},
  		}
  	},
  	computed: {
  		mySlug() {
  			return this.$route.params.slug
  		}
  	},
  	async mounted() {
  		await this.ready();
  		this.getArticle()
      this.getFoodMeter()
      this.getActivityCounter()
      this.getPradiabet()
      this.getHearth()
  		setTimeout(() => {
  			$("a[href$='informasi-kesehatan']").closest("li").addClass("active")
  		}, 300)
  	},
  	methods: {
  		getArticle() {
  			Gen.apirest("/detail-health/" + this.$route.params.sub + '/' + this.mySlug, {}, (err, resp) => {
  				if (err) console.log(err)
  				if (resp.code == 404) return this.$router.push({
  					name: '404'
  				})
  				this.article = resp.row
  				this.title_head = resp.title_head
  				this.topicTerkait = resp.terkait
  				this.relatedProduct = resp.relatedProduct
  				this.subCat = resp.subCat
          this.miniHealth = resp.miniHealth
          this.countIsactive = resp.countIsactive

          if(this.countIsactive == 1){
            setTimeout(()=>{
              $('.active_tab').css('display','none')
            },300)
          }
  			})
  		},
      getFoodMeter() {
        Gen.apirest("/food-meter", {}, (err, resp) => {
          if (err) console.log(err)
          if (resp.code == 404) return this.$router.push({
            name: '404'
          })
          this.foodMeter = resp.data
        })
      },
      getHearth() {
        Gen.apirest("/heart-disease", {}, (err, resp) => {
          if (err) console.log(err)
          if (resp.code == 404) return this.$router.push({
            name: '404'
          })
          this.heartDisease = resp.data
        })
      },
  		clickShop(v) {
  			Gen.apirest("/add-count-shop", v, (err) => {
  				this.loadingOverlay = false
  			}, "POST")
  		},
      submitFormFood(){
        Gen.apirest("/submit-foodmeter", this.foodInput, (err,resp) => {
          this.loadingOverlay = false
          if (err){
            swal("Warning!", err.resp.message, "warning")
            return;
          } 
          if (resp.code == 404){
            swal("Warning!", "Mohon isi data terlebih dahulu.", "warning")
            return;
          } 
          this.foodStatus = true
          this.resultFood = resp.data

          this.activityStatus = false
          this.resultactivity = {}

          this.pradiabetStatus = false
          this.resultPradiabet = {}
        }, "POST")
      },
      submitHeartDisease(){
        var data = JSON.stringify( $('#heartDiseaseForm').serializeArray() );
        Gen.apirest("/submit-heart-disease", {data:data,gender:this.heartInput.gender}, (err,resp) => {
          this.loadingOverlay = false
          if (err){
            swal("Warning!", err.resp.message, "warning")
            return;
          } 
          if (resp.code == 404){
            swal("Warning!", "Mohon isi data terlebih dahulu.", "warning")
            return;
          } 
          this.heartStatus = true
          this.heartDiseaseResult = resp.data

          this.pradiabetStatus = false
          this.resultPradiabet = {}

          this.foodStatus = false
          this.resultFood = {}

          this.activityStatus = false
          this.resultactivity = {}
        }, "POST")
      },
      getActivityCounter() {
        Gen.apirest("/activity-counter", {}, (err, resp) => {
          if (err) console.log(err)
          if (resp.code == 404) return this.$router.push({
            name: '404'
          })
          this.activity = resp.data
        })
      },
      submitActivityCounter(){
        Gen.apirest("/submit-activity", this.activityInput, (err,resp) => {
          this.loadingOverlay = false
          if (err){
            swal("Warning!", err.resp.message, "warning")
            return;
          } 
          if (resp.code == 404){
            swal("Warning!", "Mohon isi data terlebih dahulu.", "warning")
            return;
          } 
          this.activityStatus = true
          this.resultactivity = resp.data

          this.foodStatus = false
          this.resultFood = {}

          this.pradiabetStatus = false
          this.resultPradiabet = {}
        }, "POST")
      },      
      getPradiabet() {
        Gen.apirest("/pradiabet", {}, (err, resp) => {
          if (err) console.log(err)
          if (resp.code == 404) return this.$router.push({
            name: '404'
          })
          this.pradiabet = resp.data
        })
      },
      submitPradiabet(){
        var data = JSON.stringify( $('#pradiabetForm').serializeArray() );
        console.log(data)
        Gen.apirest("/submit-pradiabet", data, (err,resp) => {
          this.loadingOverlay = false
          if (err){
            swal("Warning!", err.resp.message, "warning")
            return;
          } 
          if (resp.code == 404){
            swal("Warning!", "Mohon isi data terlebih dahulu.", "warning")
            return;
          } 
          this.pradiabetStatus = true
          this.resultPradiabet = resp.data

          this.foodStatus = false
          this.resultFood = {}
        }, "POST")
      },
      submitBmi(){
        Gen.apirest("/submit-bmi", this.inputBmi, (err,resp) => {
          this.loadingOverlay = false
          if (err){
            swal("Warning!", err.resp.message, "warning")
            return;
          } 

          if (resp.code == 404){
            swal("Warning!", "Mohon isi data terlebih dahulu.", "warning")
            return;
          } 

          this.bmiStatus = true
          this.resultBmi = resp.data
          this.bmi = resp.score

          this.foodStatus = false
          this.resultFood = {}

          this.pradiabetStatus = false
          this.resultPradiabet = {}

          this.activityStatus = false
          this.resultactivity = {}
        }, "POST")
      }
    },
  	watch: {
  		$route() {
  			this.getArticle()
  			setTimeout(() => {
  				$("a[href$='informasi-kesehatan']").closest("li").addClass("active")
  			}, 300)
  		}
  	}
  };
</script>
<template>
  <section id="content">
    <section id="page-title" class="page-title-mini">
      <div class="container clearfix">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{name:'HealthInfoDetail',params:{slug:subCat.mht_slug}}">{{subCat.mht_title}}
            </router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">{{article.mh_title}}</li>
        </ol>
      </div>
    </section>
    <div class="content-wrap">
      <section class="section nobg nomargin">
        <div class="container">
          <div class="blog-post">
            <div class="row">
              <div class="col-md-8">
                <div class="post-body">
                  <div class="pb-head">
                    <h2 class="ph-title">{{article.mh_title}}</h2>
                    <div class="writer-block">
                      <span v-if="article.mh_author"><i class="icon-user21"></i> {{article.mh_author}}</span>
                      <!-- <span><i class="icon-calendar21"></i>{{article.datePublish}}</span> -->
                      <div class="d-block d-sm-inline-block mt-2 mt-sm-0">
                        <span class="mr-0">Share to</span>
                        <div class="social-icons share">
                          <ShareNetwork network="facebook" :url="baseUrl+$route.path" :title="article.mh_seo_title"
                            :description="article.mh_seo_desc"
                            class="social-icon si-colored si-small si-rounded si-bordered si-facebook">
                            <i class="icon-facebook"></i>
                            <i class="icon-facebook"></i>
                          </ShareNetwork>
                          <ShareNetwork network="twitter" :url="baseUrl+$route.path" :title="article.mh_seo_title"
                            class="social-icon si-colored si-small si-rounded si-bordered si-twitter">
                            <i class="icon-twitter"></i>
                            <i class="icon-twitter"></i>
                          </ShareNetwork>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <div class="post-body">
                  <div class="pb-main">
                    <img :src="uploader(article.mh_image) || assets('fo_images', 'article_alter.jpg')"
                      :alt="article.mh_title">
                    <div v-html="article.mh_desc"></div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="wrap_box_mini_check mt-0"  v-if="article.mh_show_mini_health=='Y'">
                  <img :src="assets('fo_images','deteksi.png')" alt="deteksi"/>
                  <h2>Periksa Kesehatan Sendiri</h2>
                  <p>
                    Cari tahu informasi kesehatan Anda dengan Mini-Health Check <br>Tropicana Slim!
                  </p>
                  <p><a href="#health_check"  target="" class="box-btn">PERIKSA SEKARANG</a></p>
                </div>
                <div class="related-article" v-if="topicTerkait.length">
                  <h3>Artikel Terkait</h3>
                  <router-link :to="{name:'DetailHealthArticle',params:{sub:$route.params.sub,slug:at.mh_slug}}"
                    v-for="(at,kt) in topicTerkait" :key="kt" class="ra-item">
                    <div class="row">
                      <div class="col-4">
                        <img :src="uploader(at.mh_image)" :alt="at.mh_title">
                      </div>
                      <div class="col-8">
                        <div class="ra-desc">
                          <h4>{{at.mh_title}}</h4>
                          <div class="writer-block">
                            <span><i class="icon-user21"></i>{{at.mh_author}}</span>
                            <span class="d-md-block d-lg-inline"><i
                              class="icon-calendar21"></i>{{at.datePublish}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
                <div class="related-article" v-else>
                  <h3>Artikel Terkait</h3>
                  <div class="text-center">
                    <h4>Tidak ada artikel terkait</h4>
                  </div>
                </div>
                <div class="related">
                  <h3>Produk Terkait</h3>
                  <div class="row" v-if="relatedProduct.length>0">
                    <div class="col-6 col-md-12 col-lg-6" v-for="(vr,kr) in relatedProduct">
                      <div class="r-prod">
                        <router-link :to="{name:'ProductsDetail',params:{slug:vr.mp_slug}}">
                          <img :src="uploader(vr.mp_image)" :alt="vr.mp_title">
                          <h4>{{vr.mp_title}}</h4>
                        </router-link>
                        <a :href="vr.mp_link_shop" target="_blank" @click="clickShop(vr)"
                          class="primary-btn sm-btn">Beli</a>
                      </div>
                    </div>
                  </div>
                  <div class="text-center" v-else>
                    <h4>{{web.no_produk_terkait}}</h4>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>

      <!-- Risk -->
      <section class="section bg_mini_health nomargin" id="health_check" v-if="article.mh_show_mini_health=='Y'">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-11">
              <div class="mini_health_check">
                <div class="heading_question">
                  <h3>
                    {{miniHealth}}
                  </h3>
                </div>
                <vue-tabs>

                  <!-- BMI -->
                  <v-tab title="BMI Calculator" v-if="article.mh_show_bmi=='Y'">
                    <p class="mt-4">
                      Isi data Anda untuk mengetahui Indeks Massa Tubuh
                    </p>
                    <VForm @resp="submitBmi" method="post" id="bmiForm">
                      <div class="row justify-content-center">
                        <div class="col-md-3 mb-3">
                          <label for="validationCustomUsername">Berat Badan(*)</label>
                          <div class="input-group">
                            <input type="number" min="1" v-model="inputBmi.bb" class="form-control form-control-lg" id="validationCustomUsername" placeholder="" aria-describedby="inputGroupPrepend">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="inputGroupPrepend">Kg</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3 mb-3">
                          <label for="validationCustomUsername">Tinggi Badan(*)</label>
                          <div class="input-group">
                            <input type="text" min="20" v-model="inputBmi.tb" class="form-control form-control-lg" id="validationCustomUsername" placeholder="" aria-describedby="inputGroupPrepend">
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="inputGroupPrepend">Cm</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12 text-center mt-3">
                          <button class="primary-btn ml-1">Hitung Indeks Massa Tubuh</button>
                        </div>
                      </div>
                    </VForm>
                    <div class="wrap_check_result" v-if="bmiStatus">
                      <div class="row">
                        <div class="col-md-3">
                          <h4>Skor IMT</h4>
                          <h3>{{bmi}}</h3>
                        </div>
                        <div class="col-md-3">
                          <h4>Result</h4>
                          <h3>{{resultBmi.mb_criteria}}</h3>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12" v-html="resultBmi.mb_result">
                        </div>
                      </div>
                    </div>
                  </v-tab>

                  <v-tab title="Prediabetes Risk" v-if="article.mh_pradiabet=='Y'">
                    <h4 class="mt-4">
                      Seberapa besar risiko Prediabetes Anda?
                    </h4>
                    <p style="font-size: 15px;">Prediabetes adalah kondisi dimana kadar gula darah melebihi batas normal, namun belum mencapai batasan untuk penyakit diabetes. Untungnya, prediabetes masih bisa dikembalikan ke kondisi normal apabila ditangani dengan baik. Sebaliknya, apabila tidak ditangani dengan baik, kondisi ini nantinya akan berkembang menjadi penyakit diabetes. Hal ini jelas berbahaya karena diabetes tidak dapat disembuhkan dan dapat berkaitan dengan berbagai macam komplikasi berbahaya.</p>
                    <p style="font-size: 15px;">Coba ikuti kuis singkat berikut, untuk tahu risiko Anda dalam kondisi Prediabetes.</p>
                     <VForm @resp="submitPradiabet" method="post" id="pradiabetForm">
                      <div class="question_list" v-for="(p,kp) in pradiabet" :key="kp">
                        <p>{{p.mp_question}}</p>
                        <div class="custom-control custom-radio custom-control-inline" v-for="(a,ka) in p.answer" :key="ka">
                          <input type="radio" :id="kp+'-'+ka" :name="kp" v-model="kp.score" class="custom-control-input" :value="a.skor">
                          <label class="custom-control-label" :for="kp+'-'+ka">{{a.answer}}</label>
                        </div>
                      </div>
                      <div class="col-md-12 text-center mt-4">
                        <button class="primary-btn ml-1">Health Check</button>
                      </div>
                    </VForm>
                    <div class="wrap_check_result" v-if="pradiabetStatus">
                      <div class="row">
                        <div class="col-md-12">
                          <h3 class="mt-3">Result</h3>
                          <div v-html="resultPradiabet.mpr_result"></div>
                        </div>
                      </div>
                    </div>
                    <div class="info_warn">
                      <p>
                        Kuis risiko pada website ini merupakan hasil adopsi dari kuis sejenis. Hasil dari kuis ini hanyalah berupa panduan mengenai kondisi berat badan serta tingkat risiko penyakit diabetes serta jantung dan pembuluh darah (CVD). Untuk informasi mengenai kondisi, tingkat risiko, dan penanganan yang lebih lengkap, hubungilah dokter atau ahli kesehatan terkait.
                      </p>
                    </div>
                  </v-tab>
                  <!-- Hearth Desease-->
                  <v-tab title="Heart Disease Risk" v-if="article.mh_heart_disease=='Y'">
                    <h4 class="mt-4">
                      Tingkat Resiko Penyakit Jantung
                    </h4>
                    <div class="question_list">
                        <p>Jenis Kelamin</p>
                        <div class="custom-control custom-radio custom-control-inline">
                          <input type="radio" :id="'heart01'" :name="'gender'" checked="" v-model="heartInput.gender" class="custom-control-input" value="L">
                          <label class="custom-control-label" :for="'heart01'">Pria</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                          <input type="radio" :id="'heart02'" :name="'gender'" v-model="heartInput.gender" class="custom-control-input" value="P">
                          <label class="custom-control-label" :for="'heart02'">Wanita</label>
                        </div>
                    </div>
                    <VForm @resp="submitHeartDisease" method="post" id="heartDiseaseForm">
                      <div class="question_list" v-for="(p,kp) in heartDisease" :key="kp" v-if="kp > 0">
                        <p>{{p.mhd_question}}</p>
                        <div class="custom-control custom-radio custom-control-inline" v-for="(a,ka) in p.answer" :key="ka">
                          <input type="radio" :id="kp+'id-'+ka" :name="kp+'hrt'" v-model="kp.score" class="custom-control-input" :value="heartInput.gender == 'P' ? a.skorP:a.skorL">
                          <label class="custom-control-label" :for="kp+'id-'+ka">{{a.answer}}</label>
                        </div>
                      </div>
                      <div class="col-md-12 text-center mt-4">
                        <button class="primary-btn ml-1">Health Check</button>
                      </div>
                    </VForm>
                    <div class="wrap_check_result" v-if="heartStatus">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-7">
                              <h4>Risiko Penyakit Kardiovaskular dalam 10 tahun ke depan</h4>
                              <h3>{{heartDiseaseResult.mhdr_reason}}</h3>
                            </div>
                            <div class="col-md-12 mt-3" v-html="heartDiseaseResult.mhdr_result"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="info_warn" style="font-style: italic;">
                        <h4 class="text-left">References:</h4>
                        <ol>
                          <li style="margin-left: 15px; font-size:15px;color:#000;">General Cardiovascular Risk Profile for Use in Primary Care: The Framingham Heart Study. Circulation (2008); 117: 743-753</li>
                          <li style="margin-left: 15px; font-size:15px;color:#000;">Absolute cardiovascular disease risk assessment: Quick Reference Guide for Health Professionals. National Vascular Disease Prevention Alliance</li>
                          <li style="margin-left: 15px; font-size:15px;color:#000;"><a href="https://www.cdc.gov/globalhealth/countries/indonesia/default.htm" target="_blank">US Centers for Disease Control and Prevention.</a></li>
                          <li style="margin-left: 15px; font-size:15px;color:#000;"><a href="https://medlineplus.gov/howtopreventheartdisease.html" target="_blank">MedlinePlus: US National Library of Medicine.</a></li>
                        </ol>
                    </div>  
                  </v-tab>
                  <!-- End Heart Deases-->
                  <v-tab title="Food Meter"  v-if="article.mh_food_meter=='Y'">
                    <p class="mt-4">
                      Pilih makanan atau minuman di bawah ini:
                    </p>
                      <VForm @resp="submitFormFood" method="post">
                    <div class="row justify-content-center">
                        <div class="col-md-6 mb-3">
                          <label for="validationCustomUsername">Pilih Jenis Makanan/Minuman(*)</label>
                          <select class="custom-select f_h" v-model="foodInput.data">
                            <optgroup v-for="(vf,kf) in foodMeter" :key="kf" :label="vf.mfc_name">
                              <option :value="item.mcf_id" v-for="(item,kif) in vf.items" :key="kif">{{item.mcf_menu}}</option>
                            </optgroup>
                          </select>
                        </div>
                        <div class="col-md-12 text-center mt-3">
                          <button class="primary-btn ml-1">Count Calories</button>
                        </div>
                    </div>
                      </VForm>
                    <div class="wrap_check_result" v-if="foodStatus">
                      <div class="row">
                        <div class="col-md-3">
                          <h4>Result</h4>
                          <h3>{{resultFood.mcf_menu}}</h3>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <img :src="resultFood.mcf_img ? uploader(resultFood.mcf_img) : assets('fo_images','default-img.jpg')" :alt="resultFood.mcf_menu" :title="resultFood.mcf_menu">
                        </div>
                        <div class="col-md-6">
                          <div class="table-responsive mt-3">
                            <table class="table">
                              <tbody>
                                <tr class="table-warning">
                                  <th scope="row">1 Porsi</th>
                                  <td>{{resultFood.mcf_porsi}} gram {{resultFood.mcf_note}}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Total Kalori</th>
                                  <td>{{resultFood.mcf_calorie ? resultFood.mcf_calorie + ' kal/porsi' : '-'}} </td>
                                </tr>
                                <tr class="table-warning">
                                  <th scope="row">Karbohidrat</th>
                                  <td>{{resultFood.mcf_karbo ? resultFood.mcf_karbo+' gram/porsi' : '-'}}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Protein</th>
                                  <td>{{resultFood.mcf_protein ? resultFood.mcf_protein+' gram/porsi' : '-'}}</td>
                                </tr>
                                <tr class="table-warning">
                                  <th scope="row">Fat</th>
                                  <td>{{resultFood.mcf_fat ? resultFood.mcf_fat+' gram/porsi' : '-'}}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Sugar</th>
                                  <td>{{resultFood.mcf_gula ? resultFood.mcf_gula+' gram/porsi' : '-'}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-tab>

                  <v-tab title="Activity Counter" v-if="article.mh_activity=='Y'">
                    <p class="mt-4">
                     Activity Counter
                    </p>
                      <VForm @resp="submitActivityCounter" method="post">
                    <div class="row justify-content-center">
                    	<div class="col-md-3 mb-3"><label for="validationCustomUsername">Berat Badan(*)</label><div class="input-group"><input type="number" id="validationCustomUsername" placeholder="" aria-describedby="inputGroupPrepend" class="form-control form-control-lg" v-model="activityInput.bb"><div class="input-group-prepend"><span id="inputGroupPrepend" class="input-group-text">Kg</span></div></div></div>
                      
                        <div class="col-md-6 mb-3">
                          <label for="validationCustomUsername">Pilih jenis olahraga atau aktifitas fisikmu :</label>
                          <select class="custom-select f_h" v-model="activityInput.data">
                            <option v-for="(va,ka) in activity" :key="ka" :value="va.mac_id">{{va.mac_kegiatan}}</option>
                          </select>
                        </div>
                      <div class="col-md-12 text-center mt-3">
                        <button class="primary-btn ml-1">Count Calories</button>
                      </div>
                    </div>
                      </VForm>
                    <div class="wrap_check_result" v-if="activityStatus">
                      <div class="row">
                        <div class="col-md-12">
                          <h4>Result</h4>
                          <h3>{{resultactivity.mac_kegiatan}}</h3>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          
                          <div class="table-responsive mt-3">
                            <table class="table">
                              <tbody>
                                <tr class="table-warning">
                                  <th scope="row">Total Kalori</th>
                                  <td> {{resultactivity.mac_kal}}kal/{{resultactivity.mac_waktu}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-tab>

                </vue-tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>